

































import { defineComponent, computed, ref } from '@vue/composition-api';
import { Tooltip } from '@nimiq/vue-components';
import Config from 'config';
import { useAddressStore } from '../../stores/Address';
import { usePrestakingStore } from '../../stores/Prestaking';
import { useNetworkStore } from '../../stores/Network';
import { ENV_MAIN, PRESTAKING_BLOCK_H_END, PRESTAKING_BLOCK_H_START } from '../../lib/Constants';

import HeroIcon from '../icons/Prestaking/HeroIcon.vue';

export default defineComponent({
    props: {
        asButton: {
            type: Boolean,
            required: false,
            default: true,
        },
        inversePalette: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        const { activeAddressInfo } = useAddressStore();
        const { height } = useNetworkStore();
        const { activePrestake } = usePrestakingStore();

        const hasPrestake = computed(() => !!activePrestake.value);

        const visible = computed(() => Config.environment !== ENV_MAIN
            && height.value >= PRESTAKING_BLOCK_H_START
            && (height.value <= PRESTAKING_BLOCK_H_END || hasPrestake.value));

        const $tooltip = ref<Tooltip | null>(null);
        // watch([hasPrestake, activeAddressInfo], ([has, _]) => {
        //     if (!has && $tooltip.value && visible.value) {
        //         ($tooltip.value.$el.querySelector('.trigger') as HTMLAnchorElement).focus();
        //     }
        // });

        return {
            activeAddressInfo,
            visible,
            hasPrestake,
            $tooltip,
        };
    },
    components: {
        Tooltip,
        HeroIcon,
    },
});
